var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"content-group",staticStyle:{"grid-area":"Area","grid-row-start":"Area","grid-column-start":"Area","grid-row-end":"Area","grid-column-end":"Area"}},[_vm._m(0),_c('div',{staticClass:"plan-wrapper"},[_c('div',{staticClass:"plan"},[_vm._m(1),_vm._m(2),_c('div',{staticClass:"hor-seperator"}),_c('PlanBenefits',{attrs:{"plan":"development"}}),_c('div',{staticClass:"hor-seperator"}),_c('Button',{attrs:{"text":_vm.$store.state.project.plan === 'development'
            ? 'Selected'
            : 'Select Development',"styles":_vm.$store.state.project.plan === 'development'
            ? 'light'
            : 'light outline',"isActive":_vm.$store.state.project.plan !== 'development',"iconName":_vm.$store.state.project.plan === 'development' ? 'check' : ''},on:{"click":function($event){return _vm.selectPlan('development')}}})],1),_c('div',{staticClass:"plan"},[_vm._m(3),_vm._m(4),_c('div',{staticClass:"hor-seperator"}),_c('PlanBenefits',{attrs:{"plan":"launch"}}),_c('div',{staticClass:"hor-seperator"}),_c('Button',{attrs:{"text":_vm.$store.state.project.plan === 'launch'
            ? 'Selected'
            : 'Select Launch',"styles":_vm.$store.state.project.plan === 'development'
            ? ''
            : _vm.$store.state.project.plan === 'launch'
            ? 'light'
            : 'light outline',"isActive":_vm.$store.state.project.plan !== 'launch',"iconName":_vm.$store.state.project.plan === 'launch' ? 'check' : ''},on:{"click":function($event){return _vm.selectPlan('launch')}}})],1),_c('div',{staticClass:"plan"},[_vm._m(5),_vm._m(6),_c('div',{staticClass:"hor-seperator"}),_c('PlanBenefits',{attrs:{"plan":"business"}}),_c('div',{staticClass:"hor-seperator"}),_c('Button',{attrs:{"text":_vm.$store.state.project.plan === 'development' ||
          _vm.$store.state.project.plan === 'launch'
            ? 'Select Business'
            : 'Selected',"iconName":_vm.$store.state.project.plan === 'development' ||
          _vm.$store.state.project.plan === 'launch'
            ? ''
            : 'check',"styles":_vm.$store.state.project.plan === 'development' ||
          _vm.$store.state.project.plan === 'launch'
            ? ''
            : 'light',"isActive":_vm.$store.state.project.plan !== 'business'},on:{"click":function($event){return _vm.selectPlan('business')}}})],1)]),_c('Faq'),(_vm.$store.state.upgrade.project.plan)?_c('Upgrade',{on:{"dropConfetti":_vm.dropConfetti}}):_vm._e()],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"workspace-head"},[_c('div',{staticClass:"flex-row"},[_c('h3',{staticClass:"bold _50"},[_vm._v("Plan")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space"},[_c('h5',[_vm._v("Development")]),_c('div',{staticClass:"flex-row"},[_c('h5',[_vm._v("0€")]),_c('p',[_vm._v("/month")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space to-5"},[_c('p',[_vm._v("Perfect for development and testing.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space"},[_c('h5',[_vm._v("Launch")]),_c('div',{staticClass:"flex-row"},[_c('h5',[_vm._v("19€")]),_c('p',[_vm._v("/month")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space top-5"},[_c('p',[_vm._v("Remove branding and scale your app.")])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space"},[_c('h5',[_vm._v("Business")]),_c('div',{staticClass:"flex-row"},[_c('h5',[_vm._v("99€")]),_c('p',[_vm._v("/month")])])])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"flex-row space top-5"},[_c('p',[_vm._v("Scale far beyond the launch plan limits.")])])}]

export { render, staticRenderFns }