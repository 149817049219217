
























































import { Component, Vue } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    plan: {
      type: String,
      validator: plan => {
        if (plan === "development" || plan === "launch" || plan === "business")
          return true;
        else return false;
      }
    },
    isCheckout: {
      type: Boolean,
      default: false
    }
  }
});

export default class PlanBenefits extends Props {}
