














































































import { Component, Vue, Watch } from "vue-property-decorator";
import { WorkspaceModel } from "@/models/Workspace.ts";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import Upgrade from "@/components/payment/Upgrade.vue";
import * as EmailValidator from "email-validator";
import { reportError } from "@/tools/error-handler.ts";
import User from "@/store/user";
import env from "@/envConfig";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

@Component({
  components: {
    Button,
    InputText,
    Upgrade
  }
})
export default class Collaborators extends Vue {
  newCollaboratorEmail = "";
  emailValidationError = "";
  emailIsValid = false;
  loading = false;
  deletingCollaborator = { email: "", loading: false };

  @Watch("$store.state.workspace", { immediate: true, deep: true })
  async onWorkspaceUpdate(workspace: WorkspaceModel) {
    if (Object.keys(workspace).length >= 1) {
      //
    }
  }

  validateEmailSemantically(email: string) {
    this.emailValidationError = "";
    const emailIsValid = EmailValidator.validate(email);
    if (emailIsValid) {
      this.emailIsValid = true;
      this.newCollaboratorEmail = email;
    } else {
      this.emailValidationError = "Email not vaild.";
      this.emailIsValid = false;
    }
  }

  async validateEmailByServer(email: string): Promise<boolean> {
    this.loading = true;
    const userToken = await User.getIdToken();
    const body = {
      email: email
    };
    const response = await fetch(
      `${env.serverUrl}v1/validate/collaborator-invitation`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify(body)
      }
    ).catch(error => {
      throw new Error(error.message);
    });
    this.loading = false;
    if (response.status !== 200) {
      reportError(
        "16000",
        "Request error while validating collaborator email."
      );
      return false;
    }
    const responseBody = await response.json();
    if (responseBody?.emailIsValid) {
      return true;
    } else {
      return false;
    }
  }

  async addCollaborator(): Promise<void> {
    if (this.newCollaboratorEmail === this.$store.state.user.data.email) {
      alert(
        "You can not add yourself as a collaborator. You are an admin of this workspace."
      );
      return;
    }
    if (
      this.collaborators.find(
        collaborator => collaborator.email === this.newCollaboratorEmail
      )
    ) {
      alert("You can not add the same collaborator twice.");
      return;
    }
    if (!this.emailIsValid) {
      alert("Please enter a valid email.");
      return;
    }
    alert(
      "Unfortunately collaborator additions cannot be done anymore in app.wized.io Please sign up to app.wized.com for V2"
    );
  }

  buyNewSeatForCollaborator(): void {
    reportError(
      "15000",
      "Adding extra collaborators is no longer available in Wized V1. Please visit app.wized.com to create a new project in Wized V2."
    );
  }

  async removeCollaborator(email: string): Promise<void> {
    this.deletingCollaborator = { email: email, loading: true };
    const userToken = await User.getIdToken();
    const body = {
      itemType: "workspace",
      itemId: this.$route.params.workspaceId,
      itemName: "collaborator",
      collaboratorEmail: email
    };
    const response = await fetch(
      `${env.serverUrl}v1/stripe/subscription/cancel`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify(body)
      }
    ).catch(error => {
      throw new Error(error.message);
    });
    this.deletingCollaborator = { email: "Y", loading: false };
    if (response.status !== 200) {
      throw new Error(
        "Server Error. Please try again or contact the Wized support."
      );
    }
    await this.$store.dispatch(
      "fetchWorkspace",
      this.$route.params.workspaceId
    );
  }

  dropConfetti(): void {
    this.$confetti.start({
      particles: [
        {
          type: "rect",
          size: 6
        },
        {
          type: "circle",
          size: 6
        }
      ],
      defaultColors: [
        "#6220F7",
        "#3371F2",
        "#FF715D",
        "#33ADF2",
        "#FFCE22",
        "#5BFF22"
      ]
    });
    setTimeout(() => {
      this.$confetti.stop();
    }, 6000);
  }

  formatPrice(price: number): string {
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: "EUR"
    }).format(price);
  }

  get collaborators(): Array<{ uid: string; email: string }> {
    const allCollaborators = this.$store.state.workspace.collaboratorDetails;
    const collaborators = allCollaborators.filter(
      (collaborator: { uid: string; email: string }) =>
        this.$store.state.workspace.owner !== collaborator.uid
    );
    return collaborators;
  }
}
