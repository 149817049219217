






































































import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import InputDropdown from "@/components/ui/InputDropdown.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import { Component, Vue, Watch } from "vue-property-decorator";
import { db } from "../store/firebase";
import { reportError } from "./../tools/error-handler";

@Component({
  components: {
    Button,
    InputText,
    InputDropdown,
    LoadingAnimation
  }
})
export default class Login extends Vue {
  firstName = "";
  lastName = "";
  source = "";
  sourceOther = "";
  role = "";
  companySize = "";
  sourceOptions = [
    { value: "Recommended by friend or colleague", id: "recommendation" },
    { value: "Search engine (Google, Bing, etc.)", id: "search-engine" },
    { value: "Social Media (Twitter, Facebook, Instagram)", id: "socia-media" },
    { value: "Tutorial (Youtube, Facebook Group, Blog post)", id: "tutorial" },
    {
      value: "Paid advertising (Facebook Ads, Instagram Ads, ...)",
      id: "ad"
    },
    {
      value: "Product Hunt",
      id: "product-hunt"
    },
    { value: "Other", id: "other" }
  ];
  roleOptions = [
    { value: "Freelancer", id: "freelancer" },
    { value: "Designer", id: "designer" },
    { value: "Developer", id: "developer" },
    { value: "Marketeer", id: "marketeer" },
    { value: "Company Head / CEO", id: "company-head" }
  ];
  sizeOptions = [
    { value: "0-1 employees", id: "0-1" },
    { value: "2-4 employees", id: "2-4" },
    { value: "5-10 employees", id: "5-10" },
    { value: "11-50 employees", id: "11-50" },
    { value: "51-100 employees", id: "51-100" },
    { value: ">100 employees", id: ">100" }
  ];
  loading = false;
  error = "";

  submit(): void {
    if (
      !this.firstName ||
      !this.lastName ||
      !this.source ||
      !this.role ||
      !this.companySize
    ) {
      this.error = "Please fill out all fields to continue.";
      return;
    } else {
      this.error = "";
    }
    this.loading = true;
    db.collection("users")
      .doc(this.$store.state.user.data.uid)
      .set(
        {
          firstName: this.firstName,
          lastName: this.lastName,
          signUpFormCompleted: true,
          signUpForm: {
            source: this.source,
            sourceOther: this.sourceOther,
            role: this.role,
            companySize: this.companySize
          }
        },
        { merge: true }
      )
      .then(async () => {
        this.loading = false;
        //track("user.created", 1);
        await this.$store.dispatch("fetchUserById", {
          uid: this.$store.state.user.data.uid
        });
        await this.$store.dispatch("createWorkspace");
        this.$router.push({ name: "dashboard" });
      })
      .catch(error => {
        reportError("11000", "Failed to save sign up form", error);
        this.loading = false;
      });
  }

  updateFirstName(firstName: string): void {
    this.firstName = firstName;
  }

  updateLastName(lastName: string): void {
    this.lastName = lastName;
  }

  updateSource(sourceOptionId: string): void {
    this.source = sourceOptionId;
    this.sourceOther = "";
  }

  updateSourceOther(sourceOther: string): void {
    this.sourceOther = sourceOther;
  }

  updateRole(roleOptionId: string): void {
    this.role = roleOptionId;
  }

  updateCompanySize(companySize: string): void {
    this.companySize = companySize;
  }

  @Watch("$store.state.user", { immediate: true, deep: true })
  onUserUpdate(user: any) {
    if (user.data.firstName) {
      this.firstName = user.data.firstName;
    }
    if (user.data.lastName) {
      this.lastName = user.data.lastName;
    }
  }
}
