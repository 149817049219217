









































































import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import { Component, Vue } from "vue-property-decorator";
import envConfig from "@/envConfig";
import { reportError } from "@/tools/error-handler.ts";
import User from "@/store/user";
import env from "@/envConfig";

// eslint-disable-next-line
const stripe = Stripe(envConfig.stripeKey),
  elements = stripe.elements({ locale: "en-US" });

// Create card input
const cardElement = elements.create("card", {
  style: {
    base: {
      iconColor: "#fff",
      color: "#fff",
      fontWeight: 500,
      fontFamily: "sans-serif",
      fontSize: "15px",
      ":-webkit-autofill": {
        color: "#fce883"
      },
      "::placeholder": {
        color: "rgba(255, 255, 255, 0.7)"
      }
    },
    invalid: {
      iconColor: "rgba(255, 113, 93, 1)",
      color: "rgba(255, 113, 93, 1)"
    }
  }
});

@Component({
  components: {
    Button,
    InputText
  }
})
export default class CreditCard extends Vue {
  addNewPaymentMethod = false;
  loading = false;
  error = "";

  async saveCreditCard() {
    this.loading = true;
    return stripe
      .createPaymentMethod({
        type: "card",
        card: cardElement
      })
      .then(
        (result: {
          error: { message: string };
          paymentMethod: { id: string };
        }) => {
          if (result.error) {
            this.loading = false;
            this.error = result.error.message;
          } else {
            this.attachPaymentMethodToCustomer(result.paymentMethod.id);
          }
        }
      );
  }

  async attachPaymentMethodToCustomer(paymentMethodId: string) {
    this.loading = true;
    const body = {
      paymentMethodId: paymentMethodId
    };
    const userToken = await User.getIdToken();
    const response = await fetch(
      `${env.serverUrl}v1/stripe/save-payment-method`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify(body)
      }
    ).catch(error => {
      reportError("15000", "Request Error: Save new payment method", error);
    });
    this.error = "";
    if (response && response.status !== 200) {
      reportError(
        "15001",
        "Server Error while saving new payment method",
        `Reponse status: ${response.status}`
      );
      const error = await response.json();
      if (error.error) {
        this.error = error.error.message;
        this.loading = false;
        return;
      }
    }
    await this.$store.dispatch("fetchUserById", {
      uid: this.$store.state.user.data.uid
    });
    this.addNewPaymentMethod = false;
    this.loading = false;
  }

  get userHasPaymentMethod(): boolean {
    if (this.$store.state.user.data.stripe?.paymentMethod?.id) return true;
    else return false;
  }
  get paymentMethod(): object {
    if (this.userHasPaymentMethod) {
      return this.$store.state.user.data.stripe.paymentMethod;
    } else return {};
  }

  mountCreditCardInputField() {
    cardElement.mount("#card-element");
    cardElement.on("change", (event: { error: { message: string } }) => {
      if (event.error) {
        this.error = event.error.message;
      } else {
        this.error = "";
      }
    });
  }

  async mounted() {
    this.mountCreditCardInputField();
  }
}
