



















import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    label: {
      type: String,
      default: ""
    },
    initialValueId: {
      type: String,
      default: ""
    },
    selectOptions: {
      type: Array,
      default: new Array(0),
      validator: value => {
        value.forEach(option => {
          if (!option.value || !option.id) {
            return false;
          }
        });
        return true;
      },
      required: true
    }
  }
});

@Component
export default class InputDropdown extends Props {
  value = "";

  @Watch("initialValueId")
  onInitialValueIdChange(initialValueId: string): void {
    this.value = this.selectOptions.find(
      option => initialValueId === option.id
    )?.id;
  }
  updateId(id: string): void {
    this.$emit("change", id);
  }
  mounted() {
    this.value = this.selectOptions.find(
      option => this.initialValueId === option.id
    )?.id;
  }
}
