interface ProjectModel {
  uid?: string;
  domain?: {
    isConnected?: boolean;
    name?: string;
    nameStaging?: string;
  };
  initialSetupCompleted?: boolean;
  config?: any;
  configPublished?: any;
  lastPublishedTimestamp?: number;
  id?: string;
  workspaceId?: string;
  plan?: string;
  subscription?: {
    id?: string;
    currentPeriodStart?: number;
    currentPeriodEnd?: number;
  };
  deleted?: string;
  sorting?: number;
  createdTimestamp?: number;
  updatedTimestamp?: number;
  resources?: any[];
}

const emptyProject: ProjectModel = {};

export { ProjectModel, emptyProject };
