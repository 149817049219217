































import { Component, Vue, Watch } from "vue-property-decorator";
import { WorkspaceModel } from "@/models/Workspace.ts";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";

@Component({
  components: {
    Button,
    InputText
  }
})
export default class Name extends Vue {
  workspaceName = "";
  validationError = "";
  workspaceNameIsValid = false;

  @Watch("$store.state.workspace", { immediate: true, deep: true })
  async onWorkspaceUpdate(workspace: WorkspaceModel) {
    if (Object.keys(workspace).length >= 1) {
      this.workspaceName = this.$store.state.workspace?.name;
      this.validateWorkspaceName(this.workspaceName);
    }
  }

  validateWorkspaceName(workspaceName: string): boolean {
    this.validationError = "";
    if (workspaceName && workspaceName.length <= 20) {
      this.workspaceName = workspaceName;
      this.workspaceNameIsValid = true;
      this.updateWorkspaceName();
      return true;
    } else if (!workspaceName) {
      this.validationError = "Please enter a workspace name.";
      this.workspaceNameIsValid = false;
      return false;
    } else if (workspaceName.length > 20) {
      this.validationError =
        "Please enter a workspace name shorter then 20 characters";
      this.workspaceNameIsValid = false;
      return false;
    } else {
      this.validationError = "Error. Please contact support";
      this.workspaceNameIsValid = false;
      return false;
    }
  }

  updateWorkspaceName(): void {
    const workspace = this.$store.state.workspace;
    workspace.name = this.workspaceName;
    this.$store.commit("SET_WORKSPACE", workspace);
  }
}
