



























































import { UserModel } from "@/models/User";
import { Component, Vue, Watch } from "vue-property-decorator";
import ProfileOpener from "@/components/auth/ProfileOpener.vue";
import Button from "@/components/ui/Button.vue";
import LeftPanel from "@/components/nav/LeftPanel.vue";
import Plans from "@/components/project/Plans.vue";
import Embed from "@/components/project/Embed.vue";
import Domain from "@/components/project/Domain.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import { reportError } from "@/tools/error-handler";
import User from "@/store/user";

@Component({
  components: {
    ProfileOpener,
    LeftPanel,
    Button,
    Plans,
    Embed,
    Domain,
    LoadingAnimation
  }
})
export default class Project extends Vue {
  loading = true;
  saving = false;
  saved = false;
  deleting = false;
  domainName = "";
  validationError = "";
  domainNameIsValid = false;
  projectLoaded = false;

  @Watch("$store.state.user.data", { immediate: true, deep: true })
  async onUserUpdate(user: UserModel) {
    if (user.uid && !this.projectLoaded) {
      await this.getProject();
    }
  }

  get saveProjectAllowed(): boolean {
    let result = false;
    if (
      (this.$store.state.project?.domain?.name ||
        this.$store.state.project?.domain?.nameStaging) &&
      this.$store.state.project?.domain?.isConnected
    ) {
      result = true;
    }
    return result;
  }

  async saveProject(): Promise<void> {
    this.saving = true;
    const updateSuccessful = await this.$store.dispatch("updateProject");
    if (updateSuccessful && !this.$store.state.project.initialSetupCompleted) {
      this.addInitalSetupCompleted();
    } else if (updateSuccessful) {
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    } else {
      reportError("12010", "Project could not be updated.");
    }
    this.saving = false;
  }

  async addInitalSetupCompleted(): Promise<void> {
    const project = this.$store.state.project;
    project.initialSetupCompleted = true;
    const upsateSuccessfull = await this.saveProject();
    await this.$store.commit("SET_PROJECT", project);
  }

  async deleteProject(): Promise<void> {
    if (
      this.$store.state.project.plan &&
      this.$store.state.project.plan !== "development"
    ) {
      alert("You can not delete a project with an active plan.");
      return;
    }
    const result = confirm(
      "Are you sure you want to delete this project? All project settings will be lost."
    );
    if (result) {
      this.deleting = true;
      const deleteSuccessful = await this.$store.dispatch("deleteProject");
      this.deleting = false;
      if (deleteSuccessful) {
        this.$router.push({ name: "dashboard" });
      }
    }
  }

  async getProject(): Promise<void> {
    this.projectLoaded = true;
    this.loading = true;
    await this.$store.dispatch("fetchProject", this.$route.params.projectId);
    this.loading = false;
  }

  async handleOpenConfigurator(): Promise<void> {
    const token = await User.getIdToken();
    window.open(
      "http://" +
        (this.$store.state.project.domain.nameStaging ||
          this.$store.state.project.domain.name) +
        "/?pw-t=" +
        token,
      "_blank"
    );
  }
}
