
















import { Component, Vue, Watch } from "vue-property-decorator";

const Props = Vue.extend({
  props: {
    label: {
      type: String,
      default: ""
    },
    placeholder: {
      type: String,
      default: ""
    },
    initialValue: {
      type: String,
      default: ""
    },
    error: {
      type: String,
      default: ""
    },
    maxlength: {
      type: Number,
      default: 256
    },
    isActive: {
      type: Boolean,
      default: true
    }
  }
});

@Component
export default class InputText extends Props {
  value = "";
  @Watch("initialValue")
  onInitialValueChange(initialValue: string): void {
    this.value = initialValue;
  }

  updateValue(value: string): void {
    this.$emit("change", value);
  }
  mounted(): void {
    if (this.initialValue) {
      this.value = this.initialValue;
    }
  }
}
