























































































































import { Component, Vue } from "vue-property-decorator";
import Faq from "@/components/project/Faq.vue";
import Upgrade from "@/components/payment/Upgrade.vue";
import Button from "@/components/ui/Button.vue";
import PlanBenefits from "@/components/project/PlanBenefits.vue";
import { reportError } from "@/tools/error-handler";
import VueConfetti from "vue-confetti";
Vue.use(VueConfetti);

@Component({
  components: {
    Faq,
    Upgrade,
    Button,
    PlanBenefits
  }
})
export default class Plans extends Vue {
  upgradePlan = "";

  selectPlan(plan: "development" | "launch" | "business"): void {
    if (plan === "development") {
      this.$store.commit("SET_UPGRADE", {
        type: "project",
        payload: { plan: plan, id: this.$store.state.project.id }
      });
    } else {
      reportError(
        "15000",
        "Subscription upgrades are no longer available in Wized V1. Please visit app.wized.com to create a new project in Wized V2."
      );
    }
  }

  dropConfetti(): void {
    this.$confetti.start({
      particles: [
        {
          type: "rect",
          size: 6
        },
        {
          type: "circle",
          size: 6
        }
      ],
      defaultColors: [
        "#6220F7",
        "#3371F2",
        "#FF715D",
        "#33ADF2",
        "#FFCE22",
        "#5BFF22"
      ]
    });
    setTimeout(() => {
      this.$confetti.stop();
    }, 6000);
  }
}
