import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import { firebase } from "./store/firebase";

Vue.config.productionTip = false;

firebase.auth().onAuthStateChanged(async user => {
  if (router.currentRoute.name !== "login" && !user) {
    router.replace({ name: "login" });
    return;
  } else if (router.currentRoute.name === "login" && user) {
    router.replace({ name: "dashboard" });
  }
  if (user) {
    store.dispatch("setLoginState", true);
    const dbUser = await store.dispatch("fetchUserById", {
      uid: user.uid,
      email: user.email
    });
    if (
      !dbUser.signUpFormCompleted &&
      router.currentRoute.name !== "new-user"
    ) {
      // router.replace({ name: "new-user" });
    }
  }
});

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
