









































































































































import { UserModel } from "@/models/User";
import { SubscriptionModel } from "@/models/Subscription";
import { InvoiceModel } from "@/models/Invoice";
import { ProjectModel } from "@/models/Project";
import { Component, Vue, Watch } from "vue-property-decorator";
import ProfileOpener from "@/components/auth/ProfileOpener.vue";
import LeftPanel from "@/components/nav/LeftPanel.vue";
import Button from "@/components/ui/Button.vue";
import countries from "@/misc/countryList";
import euCountries from "@/misc/euCountryList";
import CreditCard from "@/components/payment/CreditCard.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";

@Component({
  components: {
    ProfileOpener,
    LeftPanel,
    Button,
    CreditCard,
    LoadingAnimation
  }
})
export default class PlansBilling extends Vue {
  loadingSubscriptions = true;
  loadingInvoices = true;

  @Watch("$store.state.user.data", { immediate: true, deep: true })
  async onUserUpdate(user: UserModel) {
    if (user.uid) {
      await this.$store.dispatch("fetchUserSubscriptions");
      await this.$store.dispatch("fetchUserWorkspaces");
      for await (const workspace of this.$store.state.workspaces) {
        await this.$store.dispatch("fetchWorkspaceProjects", workspace.id);
      }
      this.loadingSubscriptions = false;
      await this.$store.dispatch("fetchUserInvoices");
      this.loadingInvoices = false;
    }
  }

  timestampToDate(timestamp: number) {
    return new Date(timestamp * 1000).toLocaleDateString(navigator.language);
  }

  name(id: string): { type: string; name: string; typeRaw: string } {
    let type = "Deleted project / workspace";
    let name = "";
    let typeRaw = "";
    if (this.projectName(id)) {
      type = "Project: ";
      name = this.projectName(id);
      typeRaw = "project";
    } else if (this.workspaceName(id)) {
      type = "Workspace: ";
      name = this.workspaceName(id);
      typeRaw = "workspace";
    }
    return { name, type, typeRaw };
  }

  item(subscription: any): string {
    let result = "";
    if (subscription.itemName === "launch") {
      result = "Launch Plan";
    } else if (subscription.itemName === "business") {
      result = "Business Plan";
    } else if (subscription.itemName === "collaborator") {
      const numberOfCollaborators = subscription.metadata.collaborators.split(
        ", "
      ).length;
      result =
        numberOfCollaborators.toString() +
        " Collaborator" +
        (numberOfCollaborators > 1 ? "s" : "");
    }
    return result;
  }

  price(subscription: any): string {
    let price = 0;
    if (subscription.itemName) {
      const plan = subscription.itemName;
      if (plan === "collaborator") {
        const numberOfCollaborators = subscription.metadata.collaborators.split(
          ", "
        ).length;
        price = numberOfCollaborators * 19 * (this.taxRate / 100 + 1);
      } else if (plan === "launch") {
        price = 19 * (this.taxRate / 100 + 1);
      } else if (plan === "business") {
        price = 99 * (this.taxRate / 100 + 1);
      }
    } else {
      price = 19 * (this.taxRate / 100 + 1);
    }
    return this.formatPrice(price);
  }

  workspaceName(id: string): string {
    const workspace = this.$store.state.workspaces.find(
      (workspace: any) => workspace.id === id
    );
    if (workspace) return workspace.name;
    else return "";
  }

  projectName(id: string): string {
    let projectFound: ProjectModel = {};

    for (const workspace of this.$store.state.workspaces) {
      for (const project of workspace.projects) {
        if (project.id === id) projectFound = project;
      }
    }
    if (projectFound) return projectFound.domain?.name || "";
    else return "";
  }

  openLink(url: string): void {
    window.open(url, "_blank");
  }

  formatPrice(price: number): string {
    return new Intl.NumberFormat(navigator.language, {
      style: "currency",
      currency: "EUR"
    }).format(price);
  }

  openSubscription(type: "project" | "workspace", id: string): void {
    if (type == "project")
      this.$router.push({
        name: "project",
        params: { projectId: id }
      });
    else if (type == "workspace")
      this.$router.push({
        name: "workspace",
        params: { workspaceId: id }
      });
  }

  get taxRate(): number {
    let taxRate = 0;
    const userCountry = this.$store.state.user.data.billing?.country || 0;
    const isEUCountry = euCountries.find(
      (country: any) => country.code === userCountry
    );
    if (isEUCountry) {
      taxRate = 19;
    }
    return taxRate;
  }

  get subscriptions(): Array<SubscriptionModel> {
    const result = this.$store.state.subscriptions.filter(
      (subscription: any) => !subscription.cancelTimestamp
    );
    return result;
  }

  get invoices(): Array<InvoiceModel> {
    const result = this.$store.state.invoices.filter(
      (invoice: any) => invoice.total !== 0
    );
    result.sort((a: any, b: any) => a.number - b.number);
    return result;
  }
}
