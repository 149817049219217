interface SubscriptionModel {
  stripeId?: string;
  stripeCustomerId?: string;
  createdTimestamp?: number;
  updatedTimestamp?: number;
  cancelTimestamp?: number;
  endedTimestamp?: number;
  currentPeriodEnd?: number;
  currentPeriodStart?: number;
  status?: string;
  items?: { priceId: string; productId: string }[];
  uid?: string;
  itemName?: string;
  itemType?: "project" | "workspace";
  itemId?: string;
  metadata?: {
    collaborators?: string;
    owner?: string;
  };
  id?: string;
}

const emptySubscription: SubscriptionModel = {};

export { SubscriptionModel, emptySubscription };
