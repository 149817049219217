









































































































































































import { Component, Vue } from "vue-property-decorator";
import TrendChart from "vue-trend-chart";
import Button from "@/components/ui/Button.vue";
import { SubscriptionModel } from "@/models/Subscription";
import User from "@/store/user";
import { WorkspaceModel } from "@/models/Workspace";

const Props = Vue.extend({
  props: {
    project: {
      type: Object
    }
  }
});

@Component({
  components: { TrendChart, Button }
})
export default class Project extends Props {
  usageRaw: Array<any> = [];
  trafficValue = null;
  trafficDate = "";
  loadedViewsForTimeframe = false;
  viewsForTimeframe = 0;

  async getUsage(): Promise<void> {
    this.usageRaw = await this.$store.dispatch(
      "getProjectUsage",
      this.project.id
    );
    this.actualViewsForTimeframe();
  }

  async handleOpenConfigurator(): Promise<void> {
    if (this.project.initialSetupCompleted) {
      const token = await User.getIdToken();
      window.open(
        "http://" +
          (this.project.domain.nameStaging || this.project.domain.name) +
          "/?pw-t=" +
          token,
        "_blank"
      );
    } else {
      this.openProject();
    }
  }

  openProject(): void {
    this.$router.push({
      name: "project",
      params: { projectId: this.project.id }
    });
  }

  displayCurrentValue(value: any): void {
    if (value) {
      const dateString = new Date(this.last30days[value.index] * 1000);
      const date = dateString.toLocaleString(navigator.language, {
        weekday: "short",
        month: "short",
        day: "numeric"
      });
      if (value.data.length > 0) {
        this.trafficValue = value.data[0];
      } else this.trafficValue = null;
      this.trafficDate = date;
    } else {
      this.trafficValue = null;
      this.trafficDate = "";
    }
  }

  formatDecimalNumber(number: number): string {
    return new Intl.NumberFormat(navigator.language, {
      style: "decimal"
    }).format(number);
  }

  daysSinceTimestamp(timestamp: number): number {
    const now = Math.round(Date.now() / 1000);
    const differenceInSeconds = now - timestamp;
    const differenceInDays = Math.ceil(differenceInSeconds / 60 / 60 / 24);
    return differenceInDays;
  }

  actualViewsForTimeframe(): void {
    let timeframe = 0;
    if (this.project.plan === "development") {
      timeframe = 30;
    } else if (
      this.project.plan === "launch" ||
      this.project.plan === "business"
    ) {
      timeframe = this.daysSinceTimestamp(
        this.project.subscription?.currentPeriodStart
      );
    }
    let totalViews = 0;
    for (let index = this.usage[0].data.length; timeframe >= 0; timeframe--) {
      totalViews += this.usage[0].data[index] ? this.usage[0].data[index] : 0;
      index = index - 1;
    }
    this.loadedViewsForTimeframe = true;
    this.viewsForTimeframe = totalViews;
  }

  get isAdminOfWorkspace(): boolean {
    const workspace = this.$store.state.workspaces.find(
      (workspace: WorkspaceModel) => workspace.id === this.project.workspaceId
    );
    return workspace.owner === this.$store.state.user.data.uid;
  }

  get last30days(): Array<number> {
    const today = new Date();
    const result = [];
    for (let x = -29; x <= 0; x++) {
      result.push(
        new Date(
          Date.UTC(
            today.getFullYear(),
            today.getMonth(),
            today.getDate() + x,
            0,
            0
          )
        ).getTime() / 1000
      );
    }
    return result;
  }

  get percentageOfViewsUsed(): number {
    return (this.viewsForTimeframe / this.includedViewsInTimeframe) * 100;
  }

  get includedViewsInTimeframe(): number {
    if (this.project.plan === "development") {
      return 50;
    } else if (this.project.plan === "launch") {
      return 10000;
    } else if (this.project.plan === "business") {
      return 2000000;
    } else {
      return 0;
    }
  }

  get usage(): Array<{ fill: boolean; smooth: boolean; data: Array<number> }> {
    const views: Array<number> = [];
    for (const d of this.last30days) {
      let viewD = 0;
      for (const usage of this.usageRaw) {
        if (usage.timestamp === d.toString()) {
          viewD = usage.view;
        }
      }
      views.push(viewD);
    }
    return [
      {
        fill: true,
        smooth: true,
        data: views
      }
    ];
  }

  mounted(): void {
    this.getUsage();
  }
}
