import { db, firebase } from "./firebase";
import { reportError } from "../tools/error-handler";
import envConfig from "@/envConfig";
import { InvoiceModel } from "../models/Invoice";

const fetchAll = (uid: string): Promise<Array<InvoiceModel>> => {
  return new Promise<Array<InvoiceModel>>(resolve => {
    db.collection("users")
      .doc(uid)
      .collection("invoices")
      .get()
      .then(querySnapshot => {
        const invoices: InvoiceModel[] = [];
        querySnapshot.forEach(doc => {
          const invoice = doc.data();
          invoice.id = doc.id;
          if (
            !invoice.endedTimestamp ||
            invoice.endedTimestamp < Math.round(Date.now() / 1000)
          ) {
            invoices.push(invoice);
          }
        });
        resolve(invoices.length > 0 ? invoices : []);
      })
      .catch(function(error) {
        reportError("14001", "Error while loading invoices", error);
        resolve([]);
      });
  });
};

const Invoice = {
  fetchAll
};

export default Invoice;
