



















































































import { Component, Vue, Watch } from "vue-property-decorator";
import { ProjectModel } from "@/models/Project";
import Button from "@/components/ui/Button.vue";
import { reportError } from "@/tools/error-handler";
import env from "@/envConfig";
import Prism from "prismjs";
import "prismjs/themes/prism-tomorrow.css"; // import syntax highlighting styles
import User from "@/store/user";

@Component({
  components: {
    Button
  }
})
export default class Embed extends Vue {
  codeHead =
    '<link href="https://embed.wized.io/css/app.css" rel="stylesheet">';
  codeBody =
    //eslint-disable-next-line
    `<wized projectid="${this.$route.params.projectId}"></wized><script src="https://embed.wized.io/js/app.js"><\/script>`;
  copiedCodeHead = false;
  copiedCodeBody = false;
  loading = false;
  integrationError = "";
  snippetIsValid = false;

  @Watch("$store.state.project", { immediate: true, deep: true })
  async onProjectUpdate(project: ProjectModel) {
    if (Object.keys(project).length >= 1) {
      this.validateSnippetIntegration();
    }
  }

  mounted() {
    this.validateSnippetIntegration();
  }

  get codeHeadHighlighted(): string {
    return Prism.highlight(this.codeHead, Prism.languages.html, "html");
  }

  get codeBodyHighlighted(): string {
    return Prism.highlight(this.codeBody, Prism.languages.html, "html");
  }

  async validateSnippetIntegration() {
    if (
      this.$store.state.project.domain.name ||
      this.$store.state.project.domain.nameStaging
    ) {
      this.loading = true;
      this.integrationError = "";
      const userToken = await User.getIdToken();
      const response = await fetch(`${env.serverUrl}v1/validate/snippet`, {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify({
          url:
            "https://" +
            (this.$store.state.project.domain.nameStaging ||
              this.$store.state.project.domain.name),
          projectId: this.$route.params.projectId
        })
      }).catch(error => {
        reportError(
          "12011",
          "Request error while validating domain connection",
          error
        );
      });
      this.loading = false;
      if (response && response.status == 200) {
        const responseParsed = await response.json();
        this.snippetIsValid = responseParsed.snippetIsValid;
        this.updateProjectDomainConnection();
        if (!this.snippetIsValid) {
          this.integrationError =
            "Unfortunately we were not able to find the Wized Integration on your page. Please make sure you have integrated the code exactly as stated. If this problem persists, don't hesitate to contact our support.";
        }
      } else {
        reportError(
          "12012",
          `Request returned status ${
            response ? response.status : ""
          } while validating domain connection. Should be 200.`
        );
        this.integrationError =
          "Request error. Please try again. If this error persists please contact our support";
      }
    }
  }

  updateProjectDomainConnection(): void {
    const project = this.$store.state.project;
    project.domain.isConnected = this.snippetIsValid;
    this.$store.commit("SET_PROJECT", project);
  }

  async copy(type: "head" | "body") {
    const permissionName = "clipboard-write" as PermissionName;
    const result: any = await navigator.permissions.query({
      name: permissionName
    });
    if (result.state == "granted" || result.state == "prompt") {
      const text: string = type === "head" ? this.codeHead : this.codeBody;
      try {
        await navigator.clipboard.writeText(text);
      } catch (error) {
        reportError(
          "12013",
          `Clipboard write failed for copying <${type}/> code`,
          error
        );
        alert("Clipboard write failed. Please select text manually.");
      }

      if (type === "head") {
        this.copiedCodeHead = true;
        setTimeout(() => {
          this.copiedCodeHead = false;
        }, 3000);
      } else {
        this.copiedCodeBody = true;
        setTimeout(() => {
          this.copiedCodeBody = false;
        }, 3000);
      }
    } else {
      alert("Clipboard write failed. Please select text manually.");
    }
  }
}
