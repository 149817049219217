import { db } from "./firebase";
import { reportError } from "../tools/error-handler";
import store from ".";
import { WorkspaceModel } from "../models/Workspace";

const fetchAll = (): Promise<Array<WorkspaceModel>> => {
  return new Promise(resolve => {
    db.collection("workspaces")
      .where("collaborators", "array-contains", store.state.user.data.uid)
      .get()
      .then(querySnapshot => {
        const workspaces: Array<WorkspaceModel> = [];
        querySnapshot.forEach(doc => {
          const workspace: WorkspaceModel = {
            id: doc.id,
            name: doc.data().name || "",
            owner: doc.data().owner,
            collaborators: doc.data().collaborators,
            collaboratorDetails: doc.data().collaboratorDetails,
            createdTimestamp: doc.data().createdTimestamp,
            updatedTimestamp: doc.data().updatedTimestamp,
            deleted: doc.data().deleted
          };
          if (!workspace.deleted) {
            workspaces.push(workspace);
          }
        });
        workspaces.sort((a, b) => {
          return (a.createdTimestamp || 0) - (b.createdTimestamp || 0);
        });
        resolve(workspaces);
      })
      .catch(function(error) {
        reportError("11000", "Error while loading workspaces", error);
        resolve([]);
      });
  });
};

const create = (): Promise<WorkspaceModel> => {
  return new Promise<WorkspaceModel>(resolve => {
    const uid = store.state.user.data.uid || "";
    const workspace: WorkspaceModel = {
      owner: store.state.user.data.uid,
      name: "Workspace #" + (store.state.workspaces.length + 1),
      collaborators: [uid],
      collaboratorDetails: [
        { uid: uid, email: store.state.user.data.email || "" }
      ],
      subscriptionId: "",
      createdTimestamp: Math.round(Date.now() / 1000),
      updatedTimestamp: Math.round(Date.now() / 1000)
    };
    db.collection("workspaces")
      .add(workspace)
      .then(docRef => {
        workspace.id = docRef.id;
        resolve(workspace);
      })
      .catch(function(error) {
        reportError("11001", "Error while creating workspace", error);
        resolve({});
      });
  });
};

const fetch = (workspaceId: string): Promise<WorkspaceModel> => {
  return new Promise<WorkspaceModel>(resolve => {
    db.collection("workspaces")
      .doc(workspaceId)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const workspace: WorkspaceModel = {
            id: doc.id,
            owner: doc.data()?.owner || "",
            name: doc.data()?.name || "",
            collaborators: doc.data()?.collaborators,
            collaboratorDetails: doc.data()?.collaboratorDetails,
            subscriptionId: doc.data()?.subscriptionId,
            createdTimestamp: doc.data()?.createdTimestamp,
            updatedTimestamp: doc.data()?.updatedTimestamp
          };
          resolve(workspace);
        } else {
          reportError("11002", "Workspace not found");
          resolve({});
        }
      })
      .catch(error => {
        reportError("11003", "Error while fetching workspace", error);
        resolve({});
      });
  });
};

const update = (workspace: WorkspaceModel): Promise<boolean> => {
  console.log(workspace);
  return new Promise<boolean>(resolve => {
    Object.keys(workspace).forEach(key => {
      // tslint-disable-next-line no-use-before-define
      (workspace as any)[key] === undefined && delete (workspace as any)[key];
    });
    const cleanedWorkspace = workspace;
    const id = workspace.id || "";
    cleanedWorkspace.updatedTimestamp = Date.now();
    delete cleanedWorkspace["id"];
    db.collection("workspaces")
      .doc(id)
      .set(cleanedWorkspace, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("12004", "Error while updating workspace", error);
        resolve(false);
      });
  });
};

const markAsDeleted = (workspaceId: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    db.collection("workspaces")
      .doc(workspaceId)
      .set(
        {
          deleted: true,
          deletedTimestamp: Math.round(Date.now() / 1000),
          updatedTimestamp: Math.round(Date.now() / 1000)
        },
        { merge: true }
      )
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("11005", "Error while deleting workspace", error);
        resolve(false);
      });
  });
};

const Workspace = {
  fetchAll,
  create,
  fetch,
  update,
  markAsDeleted
};

export default Workspace;
