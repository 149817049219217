import Vue from "vue";
import VueRouter, { RouteConfig } from "vue-router";
import Dashboard from "../views/Dashboard.vue";
import Login from "../views/Login.vue";
import NewUser from "../views/NewUser.vue";
import Workspace from "../views/Workspace.vue";
import PlansBilling from "../views/PlansBilling.vue";
import Project from "../views/Project.vue";
import Account from "../views/Account.vue";

Vue.use(VueRouter);

const routes: Array<RouteConfig> = [
  {
    path: "/",
    name: "dashboard",
    component: Dashboard
  },
  {
    path: "/login",
    name: "login",
    component: Login
  },
  {
    path: "/new-user",
    name: "new-user",
    component: NewUser
  },
  {
    path: "/workspace/:workspaceId",
    name: "workspace",
    component: Workspace
  },
  {
    path: "/plans-billing",
    name: "plans-billing",
    component: PlansBilling
  },
  {
    path: "/project/:projectId",
    name: "project",
    component: Project
  },
  {
    path: "/account",
    name: "account",
    component: Account
  },
];

const router = new VueRouter({
  mode: "history",
  base: process.env.BASE_URL,
  routes
});

export default router;
