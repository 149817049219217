import { db, firebase } from "./firebase";
import { reportError } from "../tools/error-handler";
import envConfig from "@/envConfig";
import { SubscriptionModel } from "../models/Subscription";

const findByItemId = (
  itemId: string,
  uid: string
): Promise<SubscriptionModel> => {
  return new Promise<SubscriptionModel>(resolve => {
    db.collection("subscriptions")
      .where("itemId", "==", itemId)
      .where("uid", "==", uid)
      .get()
      .then(querySnapshot => {
        const subscriptions: SubscriptionModel[] = [];
        querySnapshot.forEach(doc => {
          const subscription = doc.data();
          subscription.id = doc.id;
          if (!subscription.endedTimestamp) {
            subscriptions.push(subscription);
          }
        });
        resolve(subscriptions.length > 0 ? subscriptions[0] : {});
      })
      .catch(function(error) {
        reportError("14000", "Error while loading subscription", error);
        resolve({});
      });
  });
};

const fetchAll = (uid: string): Promise<Array<SubscriptionModel>> => {
  return new Promise<Array<SubscriptionModel>>(resolve => {
    db.collection("subscriptions")
      .where("uid", "==", uid)
      .get()
      .then(querySnapshot => {
        const subscriptions: SubscriptionModel[] = [];
        querySnapshot.forEach(doc => {
          const subscription = doc.data();
          subscription.id = doc.id;
          if (
            !subscription.endedTimestamp ||
            subscription.endedTimestamp < Math.round(Date.now() / 1000)
          ) {
            subscriptions.push(subscription);
          }
        });
        resolve(subscriptions.length > 0 ? subscriptions : []);
      })
      .catch(function(error) {
        reportError("14001", "Error while loading subscriptions", error);
        resolve([]);
      });
  });
};

const Subscriptions = {
  findByItemId,
  fetchAll
};

export default Subscriptions;
