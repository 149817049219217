import { db, firebase } from "./firebase";
import { reportError } from "../tools/error-handler";
import store from ".";
import { ProjectModel } from "../models/Project";

const fetchAll = (workspaceId: string): Promise<Array<ProjectModel>> => {
  return new Promise(resolve => {
    db.collection("projects")
      .where("workspaceId", "==", workspaceId)
      .get()
      .then(querySnapshot => {
        const projects: Array<object> = [];
        querySnapshot.forEach(doc => {
          const project = doc.data();
          project.id = doc.id;
          if (!project.deleted) {
            projects.push(project);
          }
        });
        resolve(projects);
      })
      .catch(function(error) {
        reportError("12000", "Error while loading projects", error);
        resolve([]);
      });
  });
};

const getUsage = (projectId: string): Promise<any> => {
  return new Promise((resolve, reject) => {
    const today = new Date();
    const todayTimestamp = (
      new Date(
        Date.UTC(today.getFullYear(), today.getMonth(), today.getDate(), 0, 0)
      ).getTime() / 1000
    ).toString();
    const aMonthAgoTimestamp = (
      new Date(
        Date.UTC(
          today.getFullYear(),
          today.getMonth(),
          today.getDate() - 31,
          0,
          0
        )
      ).getTime() / 1000
    ).toString();
    db.collection("projects")
      .doc(projectId)
      .collection("usage")
      .where(firebase.firestore.FieldPath.documentId(), "<=", todayTimestamp)
      .where(
        firebase.firestore.FieldPath.documentId(),
        ">=",
        aMonthAgoTimestamp
      )
      .get()
      .then(querySnapshot => {
        const timestamps: any = [];
        querySnapshot.forEach(function(doc) {
          const timestamp = doc.data();
          timestamp.timestamp = doc.id;
          timestamps.push(timestamp);
        });
        resolve(timestamps);
      })
      .catch(function(error) {
        reportError("12001", "Error while loading usage of project", error);
        resolve([]);
      });
  });
};

const create = (workspaceId: string): Promise<ProjectModel> => {
  return new Promise<ProjectModel>(resolve => {
    const project: ProjectModel = {
      workspaceId,
      domain: {
        isConnected: false,
        name: ""
      },
      config: {
        pages: [],
        resources: [],
        cookies: [],
        settings: {
          hasBranding: true,
          login: {
            isEnabled: false,
            userLocationValid: false,
            resourceId: "",
            baseId: "",
            tableId: "",
            fieldId: ""
          }
        },
        preview: {
          isFullScreen: false,
          settings: {
            viewAsUser: false,
            userEmail: "",
            parameters: []
          }
        }
      },
      plan: "development",
      createdTimestamp: Math.round(Date.now() / 1000),
      updatedTimestamp: Math.round(Date.now() / 1000)
    };
    db.collection("projects")
      .add(project)
      .then(docRef => {
        project.id = docRef.id;
        resolve(project);
      })
      .catch(function(error) {
        reportError("12002", "Error while creating project", error);
        resolve({});
      });
  });
};

const fetch = (projectId: string): Promise<ProjectModel> => {
  return new Promise<ProjectModel>(resolve => {
    db.collection("projects")
      .doc(projectId)
      .get()
      .then(function(doc) {
        if (doc.exists) {
          const project: ProjectModel = {
            id: doc.id,
            domain: {
              name: doc.data()?.domain?.name,
              isConnected: doc.data()?.domain?.isConnected,
              nameStaging: doc.data()?.domain?.nameStaging
            },
            config: doc.data()?.config,
            workspaceId: doc.data()?.workspaceId,
            plan: doc.data()?.plan,
            subscription: {
              id: doc.data()?.subscriptionId,
              currentPeriodStart: doc.data()?.currentPeriodStart,
              currentPeriodEnd: doc.data()?.currentPeriodEnd
            },
            initialSetupCompleted: doc.data()?.initialSetupCompleted
          };
          resolve(project);
        } else {
          reportError("12003", "Project no found");
          resolve({});
        }
      })
      .catch(error => {
        reportError("12004", "Error while fetching project", error);
        resolve({});
      });
  });
};

const update = (project: ProjectModel): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    Object.keys(project).forEach(key => {
      // tslint-disable-next-line no-use-before-define
      (project as any)[key] === undefined && delete (project as any)[key];
    });
    const cleanedProject = project;
    const id = project.id || "";
    cleanedProject.updatedTimestamp = Math.round(Date.now() / 1000);
    delete cleanedProject["id"];
    if (cleanedProject.config) delete cleanedProject["config"];
    if (cleanedProject.configPublished)
      delete cleanedProject["configPublished"];
    db.collection("projects")
      .doc(id)
      .set(cleanedProject, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("12005", "Error while updating project", error);
        resolve(false);
      });
  });
};

const updateSorting = (project: ProjectModel): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    Object.keys(project).forEach(key => {
      // tslint-disable-next-line no-use-before-define
      (project as any)[key] === undefined && delete (project as any)[key];
    });
    const cleanedProject = project;
    const id = project.id || "";
    cleanedProject.updatedTimestamp = Math.round(Date.now() / 1000);
    delete cleanedProject["id"];
    db.collection("projects")
      .doc(id)
      .set({ sorting: cleanedProject.sorting }, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("12006", "Error while updating project sorting", error);
        resolve(false);
      });
  });
};

const updateWorkspaceId = (project: ProjectModel): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    Object.keys(project).forEach(key => {
      // tslint-disable-next-line no-use-before-define
      (project as any)[key] === undefined && delete (project as any)[key];
    });
    const cleanedProject = project;
    const id = project.id || "";
    cleanedProject.updatedTimestamp = Math.round(Date.now() / 1000);
    delete cleanedProject["id"];
    db.collection("projects")
      .doc(id)
      .set({ workspaceId: cleanedProject.workspaceId }, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("12007", "Error while updating project workspaceId", error);
        resolve(false);
      });
  });
};

const markAsDeleted = (projectId: string): Promise<boolean> => {
  return new Promise<boolean>((resolve, reject) => {
    db.collection("projects")
      .doc(projectId)
      .set(
        {
          deleted: true,
          deletedTimestamp: Math.round(Date.now() / 1000),
          updatedTimestamp: Math.round(Date.now() / 1000)
        },
        { merge: true }
      )
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("12008", "Error while deleting project", error);
        resolve(false);
      });
  });
};

const Project = {
  fetchAll,
  getUsage,
  create,
  fetch,
  update,
  updateSorting,
  updateWorkspaceId,
  markAsDeleted
};

export default Project;
