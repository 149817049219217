import Vue from "vue";
import Vuex from "vuex";
import User from "./user";
import { emptyUser, UserModel } from "../models/User";
import { ProjectModel, emptyProject } from "../models/Project";
import { WorkspaceModel, emptyWorkspace } from "../models/Workspace";
import { SubscriptionModel, emptySubscription } from "../models/Subscription";
import Project from "./project";
import Workspace from "./workspace";
import Subscription from "./subscription";
import Invoice from "./invoice";
import { InvoiceModel } from "@/models/Invoice";

Vue.use(Vuex);
export default new Vuex.Store({
  state: {
    user: {
      loggedIn: false,
      data: emptyUser
    },
    projects: Array<ProjectModel>(),
    workspaces: Array<WorkspaceModel>(),
    project: emptyProject,
    workspace: emptyWorkspace,
    upgrade: {
      workspace: Object,
      project: Object
    },
    subscription: emptySubscription,
    subscriptions: Array<SubscriptionModel>(),
    invoices: Array<InvoiceModel>()
  },
  getters: {
    user(state) {
      return state.user;
    }
  },
  mutations: {
    SET_LOGGED_IN(state, value: boolean) {
      state.user.loggedIn = value;
    },
    SET_USER(state, data: UserModel) {
      state.user.data = data;
    },
    SET_PROJECTS_FOR_WORKSPACE(
      state,
      data: {
        projects: Array<object>;
        workspaceId: string;
      }
    ) {
      const workspaceIndex = state.workspaces.findIndex(
        workspace => workspace.id === data.workspaceId
      );
      state.workspaces[workspaceIndex].projects = data.projects;
    },
    SET_WORKSPACES(state, data: Array<WorkspaceModel>) {
      state.workspaces = data;
    },
    SET_WORKSPACE(state, data: object) {
      state.workspace = data;
    },
    SET_PROJECT(state, data: object) {
      state.project = data;
    },
    SET_UPGRADE(state, data: any) {
      if (data.type === "project") {
        state.upgrade.project = data.payload;
      } else if (data.type === "workspace") {
        state.upgrade.workspace = data.payload;
      }
    },
    SET_SUBSCRIPTION(state, data: object) {
      state.subscription = data;
    },
    SET_SUBSCRIPTIONS(state, data: Array<SubscriptionModel>) {
      state.subscriptions = data;
    },
    SET_INVOICES(state, data: Array<InvoiceModel>) {
      state.invoices = data;
    }
  },
  actions: {
    async setLoginState({ commit }, isLoggedIn) {
      commit("SET_LOGGED_IN", isLoggedIn);
    },
    async fetchUserById({ commit }, user: UserModel) {
      let dbUser: UserModel = await User.findById(user.uid || "");
      if (Object.keys(dbUser).length === 0) {
        user.createdTimestamp = Math.round(Date.now() / 1000);
        user.updatedTimestamp = Math.round(Date.now() / 1000);
        dbUser = await User.create(user);
      }
      if (!dbUser.stripe || !dbUser.stripe.customerId) {
        // TODO: Create Stripe Custome & Save Id
        dbUser = await User.createStripeCustomer(dbUser);
      }
      commit("SET_USER", dbUser);
      return dbUser;
    },
    async fetchUserWorkspaces({ commit }) {
      const workspaces: Array<WorkspaceModel> = await Workspace.fetchAll();
      commit("SET_WORKSPACES", workspaces);
      return workspaces;
    },
    async fetchWorkspaceProjects({ commit }, workspaceId: string) {
      const projects: Array<ProjectModel> = await Project.fetchAll(workspaceId);
      commit("SET_PROJECTS_FOR_WORKSPACE", { projects, workspaceId });
      return projects;
    },
    async getProjectUsage(context, projectId: string) {
      const projectUsage: Array<any> = await Project.getUsage(projectId);
      return projectUsage;
    },
    async createProject(context, workspaceId: string): Promise<ProjectModel> {
      const project: ProjectModel = await Project.create(workspaceId);
      return project;
    },
    async createWorkspace(context): Promise<WorkspaceModel> {
      const workspace: WorkspaceModel = await Workspace.create();
      return workspace;
    },
    async fetchProject(
      { commit, state },
      projectId: string
    ): Promise<ProjectModel> {
      const project: ProjectModel = await Project.fetch(projectId);
      const subscription: SubscriptionModel = await Subscription.findByItemId(
        project.id ? project.id : "",
        state.user.data.uid ? state.user.data.uid : ""
      );
      commit("SET_PROJECT", project);
      commit("SET_SUBSCRIPTION", subscription);
      return project;
    },
    async fetchWorkspace(
      { commit, state },
      workspaceId: string
    ): Promise<WorkspaceModel> {
      const workspace: WorkspaceModel = await Workspace.fetch(workspaceId);
      const subscription: SubscriptionModel = await Subscription.findByItemId(
        workspace.id ? workspace.id : "",
        state.user.data.uid ? state.user.data.uid : ""
      );
      commit("SET_WORKSPACE", workspace);
      commit("SET_SUBSCRIPTION", subscription);
      return workspace;
    },
    async updateProject({ state }): Promise<boolean> {
      const projectString = JSON.stringify(state.project);
      const projectObject = JSON.parse(projectString);
      const updateSuccessful: boolean = await Project.update(projectObject);
      return updateSuccessful;
    },
    async updateProjectSorting({ state }, project): Promise<boolean> {
      const projectString = JSON.stringify(project);
      const projectObject = JSON.parse(projectString);
      const updateSuccessful: boolean = await Project.updateSorting(
        projectObject
      );
      return updateSuccessful;
    },
    async updateProjectWorkspaceId({ state }, project): Promise<boolean> {
      const projectString = JSON.stringify(project);
      const projectObject = JSON.parse(projectString);
      const updateSuccessful: boolean = await Project.updateWorkspaceId(
        projectObject
      );
      return updateSuccessful;
    },
    async updateWorkspace({ state }): Promise<boolean> {
      const workspaceString = JSON.stringify(state.workspace);
      const workspaceObject = JSON.parse(workspaceString);
      const updateSuccessful: boolean = await Workspace.update(workspaceObject);
      return updateSuccessful;
    },
    async deleteProject({ state }): Promise<boolean> {
      const deleteSuccessful: boolean = await Project.markAsDeleted(
        state.project.id || ""
      );
      return deleteSuccessful;
    },
    async deleteWorkspace({ state }): Promise<boolean> {
      const deleteSuccessful: boolean = await Workspace.markAsDeleted(
        state.workspace.id || ""
      );
      return deleteSuccessful;
    },
    async updateUser({ state }): Promise<boolean> {
      const userString = JSON.stringify(state.user.data);
      const userObject = JSON.parse(userString);
      const updateSuccessful: boolean = await User.update(userObject);
      return updateSuccessful;
    },
    async fetchUserSubscriptions({
      state,
      commit
    }): Promise<SubscriptionModel[]> {
      const subscriptions: Array<SubscriptionModel> = await Subscription.fetchAll(
        state.user.data.uid || ""
      );
      commit("SET_SUBSCRIPTIONS", subscriptions);
      return subscriptions;
    },
    async fetchUserInvoices({ state, commit }): Promise<InvoiceModel[]> {
      const invoices: Array<InvoiceModel> = await Invoice.fetchAll(
        state.user.data.uid || ""
      );
      commit("SET_INVOICES", invoices);
      return invoices;
    }
  },
  modules: {}
});
