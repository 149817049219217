var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"full-page-popup"},[_c('div',{staticClass:"con"},[_c('div',{staticClass:"login-wrapper"},[_vm._m(0),_c('div',{staticClass:"flex-row space"},[(_vm.project.id)?_c('h1',{staticClass:"bottom-5"},[_vm._v(" Change project plan ")]):_vm._e(),(_vm.workspace.id)?_c('h1',{staticClass:"bottom-5"},[_vm._v(" Add Collaborator ")]):_vm._e(),_c('Button',{attrs:{"size":"big","iconName":"close","styles":"light","isSquare":true},on:{"click":_vm.close}})],1),_c('div',{staticClass:"login-block"},[_c('h3',{staticClass:"bold _50 bottom-15"},[_vm._v("Details")]),(_vm.project.id)?_c('p',{staticClass:"bottom-5 _100"},[_c('span',{staticClass:"_50"},[_vm._v("Project:")]),_vm._v(" "+_vm._s(_vm.$store.state.project.domain.name)+" ")]):_vm._e(),(_vm.workspace.id)?_c('p',{staticClass:"bottom-5 _100"},[_c('span',{staticClass:"_50"},[_vm._v("Workspace:")]),_vm._v(" "+_vm._s(_vm.$store.state.workspace.name)+" ")]):_vm._e(),(_vm.workspace.id)?_c('p',{staticClass:"bottom-5 _100"},[_c('span',{staticClass:"_50"},[_vm._v("Collaborator:")]),_vm._v(" "+_vm._s(_vm.workspace.email)+" ")]):_vm._e(),_c('div',{staticClass:"hor-seperator",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}}),(_vm.project.id)?_c('div',[_c('div',{staticClass:"flex-row top-5 bottom-15"},[_c('h3',{staticClass:"bold _50"},[_vm._v("Plan")]),_c('div',{staticClass:"plan-div left-10",class:{
                live: _vm.project.plan === 'launch',
                business: _vm.project.plan === 'business'
              }},[_c('img',{staticClass:"_10px-icon\n      right-5",attrs:{"src":_vm.project.plan === 'launch'
                    ? '/images/plan-black.svg'
                    : '/images/plan-white.svg',"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block plan-font"},[_vm._v(" "+_vm._s(_vm.project.plan === "launch" ? "LAUNCH" : _vm.project.plan === "business" ? "BUSINESS" : "DEVELOPMENT")+" ")])])]),_c('PlanBenefits',{attrs:{"plan":_vm.project.plan,"isCheckout":true}}),_c('div',{staticClass:"hor-seperator",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}})],1):_vm._e(),_c('div',{staticClass:"plan-benefits"},[_c('h3',{staticClass:"bold _50 bottom-15"},[_vm._v("Total")]),_c('div',{staticClass:"flex-row bottom-5"},[_c('h5',[_vm._v(_vm._s(_vm.price)+"€")]),_c('p',[_vm._v("/month")]),_c('p',{staticClass:"left-5 _50"},[_vm._v("- including "+_vm._s(_vm.taxRate)+"% VAT")])])]),_c('div',{staticClass:"hor-seperator",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}}),(_vm.paymentMethodNeeded)?_c('div',[_c('h3',{staticClass:"bold _50 top-15"},[_vm._v("Billing")]),_c('InputDropdown',{staticStyle:{"margin-top":"15px"},attrs:{"label":"Country","initialValueId":_vm.$store.state.user.data.billing &&
              _vm.$store.state.user.data.billing.country
                ? _vm.$store.state.user.data.billing.country
                : '',"selectOptions":_vm.countriesFormatted},on:{"change":_vm.updateCountry}}),_c('CreditCard'),_c('div',{staticClass:"hor-seperator",staticStyle:{"margin-top":"20px","margin-bottom":"20px"}})],1):_vm._e(),_c('Button',{attrs:{"text":_vm.button.text,"styles":_vm.button.styles,"size":"big","isActive":(_vm.userHasPaymentMethod || !_vm.paymentMethodNeeded) &&
              (Boolean(
                _vm.$store.state.user.data.billing &&
                  _vm.$store.state.user.data.billing.country
              ) ||
                _vm.$store.state.upgrade.project.plan === 'development'),"loading":_vm.loading},on:{"click":_vm.handleSubscriptionChange}}),(_vm.error)?_c('p',{staticClass:"red top-10"},[_vm._v(_vm._s(_vm.error))]):_vm._e()],1)])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"top-row bottom-40"},[_c('div',{staticClass:"logo-block"},[_c('img',{staticClass:"pw-logo",attrs:{"src":"/images/wized-dark.svg","loading":"lazy","alt":""}})])])}]

export { render, staticRenderFns }