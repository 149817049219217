const mode = process.env.NODE_ENV; // 'development', 'test', 'production'

const env = {
  mode: mode,
  serverUrl: "",
  stripeKey: ""
};

if (mode === "development") {
  env.serverUrl = "http://localhost:3000/";
  env.stripeKey = `pk_test_7PjHWPLH8kexOavj8P7l05ob00f9Mm9Tbu`;
} else if (mode === "production") {
  env.serverUrl = "https://api.wized.io/";
  env.stripeKey = `pk_live_DxMBpAh3TTrumRlYYOnWOmAk00cGIYHCoh`;
}

export default env;
