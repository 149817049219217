
























































// @ts-nocheck

import { Component, Vue, Watch } from "vue-property-decorator";
import ProfileOpener from "@/components/auth/ProfileOpener.vue";
import LeftPanel from "@/components/nav/LeftPanel.vue";
import Workspace from "@/components/dashboard/Workspace.vue";
import { WorkspaceModel } from "@/models/Workspace.ts";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import { UserModel } from "../models/User";
import { reportError } from "@/tools/error-handler";
import User from "@/store/user";
import env from "@/envConfig";

@Component({
  components: {
    ProfileOpener,
    LeftPanel,
    Workspace,
    LoadingAnimation
  }
})
export default class Dashboard extends Vue {
  loading = true;
  projectSearch = "";

  async createWorkspace(): Promise<void> {
    const workspace: WorkspaceModel = await this.$store.dispatch(
      "createWorkspace"
    );
    if (workspace.id) {
      this.$router.push({
        name: "workspace",
        params: { workspaceId: workspace.id }
      });
    } else {
      reportError("11010", "Workspace could not be created");
    }
  }

  @Watch("$store.state.user.data", { immediate: true, deep: true })
  async onUserUpdate(user: UserModel) {
    if (user.uid) {
      this.loading = true;
      this.refreshUserToken();

      // // Initate Zendesk user identification
      // // eslint-disable-next-line no-undef
      // zE("webWidget", "identify", {
      //   name: user.firstName + " " + user.lastName,
      //   email: user.email
      // });

      // Initate Zendesk for logged in users
      // const userToken = await User.getIdToken();
      // window.zESettings = {
      //   webWidget: {
      //     authenticate: {
      //       chat: {
      //         jwtFn: function(callback) {
      //           fetch(`${env.serverUrl}v1/zendesk/auth`, {
      //             method: "GET",
      //             headers: {
      //               Authorization: "Bearer " + userToken
      //             }
      //           }).then(function(res) {
      //             res.json().then(function(res) {
      //               callback(res.token);
      //             });
      //           });
      //         }
      //       }
      //     }
      //   }
      // };

      await this.$store.dispatch("fetchUserWorkspaces");
      for await (const workspace of this.$store.state.workspaces) {
        await this.$store.dispatch("fetchWorkspaceProjects", workspace.id);
      }
      this.loading = false;

      // // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // this.$intercom.boot({
      //   // eslint-disable-next-line @typescript-eslint/camelcase
      //   user_id: user.uid,
      //   email: user.email,
      //   name: user.firstName
      // });
    }
  }

  async refreshUserToken(): Promise<void> {
    if (
      this.$route.query.projectId &&
      this.$route.query.refresh &&
      this.$route.query.url
    ) {
      const userToken = await User.getIdToken();
      const url = new URL(this.$route.query.url);
      const params = url && new URLSearchParams(url.search);
      params.set("pw-t", userToken);
      url.search = "?" + params.toString();
      window.location.href = url.toString();
    }
  }
}
