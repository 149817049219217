import { ProjectModel } from "./Project";

interface WorkspaceModel {
  id?: string;
  owner?: string;
  name?: string;
  collaborators?: Array<string>;
  collaboratorDetails?: Array<{ uid: string; email: string }>;
  projects?: Array<ProjectModel>;
  deleted?: boolean;
  subscriptionId?: string;
  createdTimestamp?: number;
  updatedTimestamp?: number;
}

const emptyWorkspace: WorkspaceModel = {};

export { WorkspaceModel, emptyWorkspace };
