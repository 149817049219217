











































































































































import { Component, Vue, Watch } from "vue-property-decorator";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import InputDropdown from "@/components/ui/InputDropdown.vue";
import PlanBenefits from "@/components/project/PlanBenefits.vue";
import CreditCard from "@/components/payment/CreditCard.vue";
import countries from "@/misc/countryList";
import euCountries from "@/misc/euCountryList";
import { reportError } from "@/tools/error-handler.ts";
import env from "@/envConfig";
import User from "@/store/user";

//https://stripe.com/docs/billing/subscriptions/fixed-price#provision-access

// eslint-disable-next-line
const stripe = Stripe(env.stripeKey),
  elements = stripe.elements({ locale: navigator.language });

@Component({
  components: {
    Button,
    InputText,
    InputDropdown,
    PlanBenefits,
    CreditCard
  }
})
export default class Upgrade extends Vue {
  loading = false;
  error = "";
  userCountry = "";
  taxRate = 0;

  mounted() {
    this.calcTaxRate();
  }

  updateCountry(value: string): void {
    this.userCountry = value;
    const user = this.$store.state.user.data;
    if (!user.billing) user.billing = {};
    user.billing.country = value;
    this.$store.commit("SET_USER", user);
    this.$store.dispatch("updateUser");
  }

  get countriesFormatted(): Array<any> {
    const result: any = [];
    countries.forEach((country: any) => {
      result.push({ id: country.code, value: country.name });
    });
    return result;
  }

  @Watch("userCountry")
  calcTaxRate(): void {
    const userCountry =
      this.$store.state.user.data.billing?.country || this.userCountry;
    const isEUCountry = euCountries.find(
      (country: any) => country.code === userCountry
    );
    if (isEUCountry) {
      this.taxRate = 19;
    } else this.taxRate = 0;
  }

  get price(): string {
    let price = 0;
    if (this.project.plan) {
      const plan = this.project.plan;
      if (plan === "development") {
        price = 0;
      } else if (plan === "launch") {
        price = 19 * (this.taxRate / 100 + 1);
      } else if (plan === "business") {
        price = 99 * (this.taxRate / 100 + 1);
      }
    } else {
      price = 19 * (this.taxRate / 100 + 1);
    }
    return price.toFixed(2);
  }

  get project(): { plan: string; id: string } {
    return {
      plan: this.$store.state.upgrade.project.plan,
      id: this.$store.state.upgrade.project.id
    };
  }

  get workspace(): { id: string; email: string } {
    return {
      id: this.$store.state.upgrade.workspace.id,
      email: this.$store.state.upgrade.workspace.email
    };
  }

  get userHasPaymentMethod(): boolean {
    if (this.$store.state.user.data.stripe?.paymentMethod?.id) return true;
    else return false;
  }

  get paymentMethodNeeded(): boolean {
    if (this.$store.state.upgrade.project.plan === "development") {
      return false;
    } else return true;
  }

  get button(): { text: string; styles: string } {
    if (this.workspace.id) {
      return {
        text: "Add collaborator",
        styles: ""
      };
    }
    if (
      this.$store.state.upgrade.project.plan === "development" ||
      (this.project.plan === "launch" &&
        this.$store.state.project.plan === "business")
    ) {
      return {
        text:
          "Downgrade to " + this.$store.state.upgrade.project.plan + " plan",
        styles: "light"
      };
    } else {
      return {
        text:
          "Upgrade to " +
          (this.$store.state.upgrade.project.plan === "launch"
            ? "launch plan"
            : "business plan"),
        styles: ""
      };
    }
  }

  async handleSubscriptionChange(): Promise<void> {
    if (this.project.plan === "development") {
      await this.modifySubscription("cancel");
    } else {
      this.showError(
        "Unfortunately subscription changes cannot be done anymore in app.wized.io Please sign up to app.wized.com for V2"
      );
    }
  }

  async modifySubscription(method: "update" | "cancel"): Promise<void> {
    const userToken = await User.getIdToken();
    const body = {
      itemType: this.project.id ? "project" : "workspace",
      itemId: this.project.id ? this.project.id : this.workspace.id,
      itemName: this.project.id ? this.project.plan : "collaborator",
      collaboratorEmail: this.workspace.email ? this.workspace.email : ""
    };
    const response = await fetch(
      `${env.serverUrl}v1/stripe/subscription/${method}`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify(body)
      }
    ).catch(error => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      if (this.project.id) {
        await this.$store.dispatch(
          "fetchProject",
          this.$route.params.projectId
        );
      } else if (this.workspace.id) {
        await this.$store.dispatch(
          "fetchWorkspace",
          this.$route.params.workspaceId
        );
      }
      if (method === "update") {
        this.$emit("dropConfetti");
      }
      this.loading = false;
      this.close();
    } else {
      this.loading = false;
      throw new Error(
        "Server Error. Please try again or contact the Wized support."
      );
    }
  }

  async createSubscription(): Promise<void> {
    this.showError(
      "New subscriptions are disabled in Wized V1. Please visit app.wized.com to create a new project in Wized V2."
    );
  }

  async handlePaymentThatRequiresCustomerAction(
    subscription: any,
    priceId: string,
    paymentMethodId: string,
    invoice?: any,
    isRetry?: boolean
  ) {
    if (subscription && subscription.status === "active") {
      // Subscription is active, no customer actions required.
      return { subscription, priceId, paymentMethodId };
    }

    // If it's a first payment attempt, the payment intent is on the subscription latest invoice.
    // If it's a retry, the payment intent will be on the invoice itself.
    const paymentIntent = invoice
      ? invoice.payment_intent
      : subscription.latest_invoice.payment_intent;

    if (
      paymentIntent.status === "requires_action" ||
      (isRetry === true && paymentIntent.status === "requires_payment_method")
    ) {
      return stripe
        .confirmCardPayment(paymentIntent.client_secret, {
          // eslint-disable-next-line @typescript-eslint/camelcase
          payment_method: paymentMethodId,
          // eslint-disable-next-line @typescript-eslint/camelcase
          setup_future_usage: "off_session"
        })
        .then((result: any) => {
          if (result.error) {
            // Start code flow to handle updating the payment details.
            // Display error message in your UI.
            // The card was declined (i.e. insufficient funds, card has expired, etc).
            throw result;
          } else {
            if (result.paymentIntent.status === "succeeded") {
              // Show a success message to your customer.
              return {
                priceId: priceId,
                subscription: subscription,
                invoice: invoice,
                paymentMethodId: paymentMethodId
              };
            }
          }
        })
        .catch(() => {
          throw new Error(
            "Could not authenticate credit card. Please try another credit card or contact the Wized support."
          );
        });
    } else {
      // No customer action needed.
      return { subscription, priceId, paymentMethodId };
    }
  }

  async onSubscriptionCreateComplete(result: any) {
    if (result.subscription.status === "active") {
      // Payment was successful.
      // Change your UI to show a success message to your customer.
      // Call your backend to grant access to your service based on
      // `result.subscription.items.data[0].price.product` the customer subscribed to.
      const subscriptionStatus = await this.checkSubscriptionStatus(
        result.subscription.id
      );
      if (subscriptionStatus.isActive) {
        if (this.project.id) {
          await this.$store.dispatch(
            "fetchProject",
            this.$route.params.projectId
          );
        } else if (this.workspace.id) {
          await this.$store.dispatch(
            "fetchWorkspace",
            this.$route.params.workspaceId
          );
        }
        this.$emit("dropConfetti");
        this.loading = false;
        this.close();
      } else {
        throw new Error(
          "Subscription could not be activated. Please contact the Wized support."
        );
      }
    } else if (result.subscription.status === "incomplete") {
      if (
        result.subscription.latest_invoice?.payment_intent?.status ===
        "requires_payment_method"
      ) {
        throw new Error(
          "Could not charge credit card. This could be because you turned off online payments or charges in foreign currencies for this credit card. Please try another card or contact the Wized support."
        );
      } else if (
        result.subscription.latest_invoice?.payment_intent?.status ===
        "requires_actionY"
      ) {
        throw new Error(
          "Could not authenticate credit card. Please try another credit card or contact the Wized support."
        );
      }
    }
  }

  async checkSubscriptionStatus(
    subscriptionId: string
  ): Promise<{ isActive: boolean }> {
    const userToken = await User.getIdToken();
    const response = await fetch(
      `${env.serverUrl}v1/stripe/subscription/check`,
      {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
          Authorization: "Bearer " + userToken
        },
        body: JSON.stringify({ subscriptionId: subscriptionId })
      }
    ).catch(error => {
      throw new Error(error.message);
    });
    if (response.status === 200) {
      const subscriptionStatus: any = await response.json();
      return { isActive: subscriptionStatus.isActive };
    } else {
      throw new Error(
        "Error while checking subscription status. Please contact the Wized support."
      );
    }
  }

  close(): void {
    this.$store.commit("SET_UPGRADE", {
      type: "project",
      payload: {}
    });
    this.$store.commit("SET_UPGRADE", {
      type: "workspace",
      payload: {}
    });
  }

  showError(error: string) {
    this.error = error;
  }

  get priceId(): string {
    const itemName = this.project.id ? this.project.plan : "collaborator";
    let priceId = "";
    switch (itemName) {
      case "launch":
        priceId =
          env.mode === "development" ? "price_1ITEGaA9w8Ik1iuPOIa5RIZt" : "";
        break;
      case "business":
        priceId =
          env.mode === "development" ? "price_1ITEGyA9w8Ik1iuPFTFtS4A4" : "";
        break;
      case "collaborator":
        priceId =
          env.mode === "development" ? "price_1IW0EwA9w8Ik1iuP4xccz0nZ" : "";
        break;
      default:
        priceId = "";
        break;
    }
    return priceId;
  }
}
