import firebase from "firebase/app";
import "firebase/auth";
import "firebase/firestore";
import "firebase/functions";
import "firebase/analytics";
import env from "../envConfig";

const firebaseConfigProd = {
  apiKey: "AIzaSyCDjpww4efHLLCHhv14U0gp7HpYboNRGpY",
  authDomain: "wized-prod.firebaseapp.com",
  projectId: "wized-prod",
  storageBucket: "wized-prod.appspot.com",
  messagingSenderId: "170541234605",
  appId: "1:170541234605:web:f217efbee5e2391846c924",
  measurementId: "G-M4QRFSXKHF"
};
const firebaseConfigTest = {
  apiKey: "AIzaSyAS11XVuMe5CuQFH0xUVdAVX83J1cNQdKM",
  authDomain: "wized-staging.firebaseapp.com",
  projectId: "wized-staging",
  storageBucket: "wized-staging.appspot.com",
  messagingSenderId: "594808127025",
  appId: "1:594808127025:web:ef33d30c2d10bf6a265f10",
  measurementId: "G-SNVHBN39DK"
};
firebase.initializeApp(
  env.mode === "development" ? firebaseConfigTest : firebaseConfigProd
);
const db = firebase.firestore();
const analytics = firebase.analytics();

export { db, firebase, analytics };
