




























































































import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import { Component, Vue } from "vue-property-decorator";
import { firebase } from "../store/firebase";

@Component({
  components: {
    Button,
    InputText,
    LoadingAnimation
  }
})
export default class Login extends Vue {
  email = "";
  error = "";
  loading = false;
  emailSent = false;

  handleEmailChange(value: string): void {
    this.email = value;
  }

  sendMagicLink(): void {
    this.error = "";
    const redirectUrl =
      process.env.NODE_ENV == "development"
        ? "http://localhost:8080/login"
        : "https://app.wized.io/login";
    const actionCodeSettings = {
      url: redirectUrl,
      handleCodeInApp: true
    };
    this.loading = true;
    const firebaseAuth = firebase.auth();
    firebaseAuth
      .fetchSignInMethodsForEmail(this.email)
      .then(signInMethods => {
        if (!signInMethods.length) {
          this.error =
            "New sign ups are disabled in Wized V1. Please visit app.wized.com to create a new project in Wized V2.";
          this.loading = false;

          return;
        }
        firebase
          .auth()
          .sendSignInLinkToEmail(this.email, actionCodeSettings)
          .then(() => {
            window.localStorage.setItem("emailForSignIn", this.email);
            console.log("Login link sent");
            this.emailSent = true;
            this.loading = false;
          })
          .catch(error => {
            this.error = error.message;
            this.loading = false;
          });
      })
      .catch(error => {
        this.loading = false;
        this.error = error.message;
      });
  }

  signInWithGoogle(): void {
    const provider = new firebase.auth.GoogleAuthProvider();
    firebase.auth().languageCode = "en";
    firebase
      .auth()
      .signInWithPopup(provider)
      .then(result => {
        /** @type {firebase.auth.OAuthCredential} */
        /*
        const credential = result.credential;
                const user = result.user;
                        var token = credential.accessToken;
                */
        /*
        // This gives you a Google Access Token. You can use it to access the Google API.
        // The signed-in user info.
        */
        // ...
      })
      .catch(error => {
        console.log(error);
        /* 
        // Handle Errors here.
        var errorCode = error.code;
        var errorMessage = error.message;
        // The email of the user's account used.
        var email = error.email;
        // The firebase.auth.AuthCredential type that was used.
        var credential = error.credential;
        // ... */
      });
  }

  mounted() {
    // Detect & Sign in magic link login
    if (firebase.auth().isSignInWithEmailLink(window.location.href)) {
      let email = window.localStorage.getItem("emailForSignIn");
      if (!email) {
        // User opened the link on a different device. To prevent session fixation
        // attacks, ask the user to provide the associated email again. For example:
        email = window.prompt(
          "You opened the magic link with another device / browser. Please provide your email for confirmation."
        );
      }
      this.loading = true;
      firebase
        .auth()
        .signInWithEmailLink(email || "", window.location.href)
        .then(async result => {
          window.localStorage.removeItem("emailForSignIn");

          const user = result.user;
          console.log(user, result.additionalUserInfo?.isNewUser);
          this.loading = false;
          // You can check if the user is new or existing:
          // result.additionalUserInfo.isNewUser
        })
        .catch(error => {
          this.error = error || "";
          this.loading = false;
        });
    }
  }
}
