
















































import { Component, Vue, Watch } from "vue-property-decorator";
import { ProjectModel } from "@/models/Project";
import Button from "@/components/ui/Button.vue";
import InputText from "@/components/ui/InputText.vue";
import isValidDomain from "is-valid-domain";

@Component({
  components: {
    Button,
    InputText
  }
})
export default class Domain extends Vue {
  domainName = "";
  domainNameStaging = "";
  validationError = "";
  domainNameIsValid = false;
  validationErrorStaging = "";
  domainNameIsValidStaging = false;

  @Watch("$store.state.project", { immediate: true, deep: true })
  async onProjectUpdate(project: ProjectModel) {
    if (Object.keys(project).length >= 1) {
      this.domainName = this.$store.state.project.domain.name;
      this.domainNameStaging = this.$store.state.project.domain.nameStaging;
      this.validateDomainName(this.domainName);
      this.validateDomainNameStaging(this.domainNameStaging);
    }
  }

  validateDomainName(domainName: string): void {
    this.domainName = domainName.toLowerCase().replace(/\s/g, "");
    if (isValidDomain(this.domainName, { subdomain: true })) {
      this.domainNameIsValid = true;
      this.validationError = "";
      this.updateProjectDomainName();
    } else {
      this.domainNameIsValid = false;
      if (this.domainName) {
        this.validationError =
          "Please enter a valid domain name. (Valid examples: mysite.com, subdomain.mysite.com)";
      } else {
        this.validationError = "";
      }
    }
  }

  validateDomainNameStaging(domainName: string): void {
    this.domainNameStaging = domainName.toLowerCase().replace(/\s/g, "");
    if (!this.domainNameStaging) {
      this.domainNameIsValidStaging = true;
      this.updateProjectDomainName();
      return;
    }
    if (
      isValidDomain(this.domainNameStaging, { subdomain: true }) &&
      this.domainNameStaging.substr(-".webflow.io".length) === ".webflow.io"
    ) {
      this.domainNameIsValidStaging = true;
      this.validationErrorStaging = "";
      this.updateProjectDomainName();
    } else {
      this.domainNameIsValidStaging = false;
      if (this.domainName) {
        this.validationErrorStaging =
          "Please enter a valid webflow staging domain name. (Valid example: mysite.webflow.io)";
      } else {
        this.validationErrorStaging = "";
      }
    }
  }

  updateProjectDomainName(): void {
    const project = this.$store.state.project;
    project.domain.name = this.domainName;
    project.domain.nameStaging = this.domainNameStaging;
    this.$store.commit("SET_PROJECT", project);
  }
}
