











































import { UserModel } from "@/models/User";
import { Component, Vue, Watch } from "vue-property-decorator";
import ProfileOpener from "@/components/auth/ProfileOpener.vue";
import LeftPanel from "@/components/nav/LeftPanel.vue";
import Button from "@/components/ui/Button.vue";
import Name from "@/components/workspace/Name.vue";
import Collaborators from "@/components/workspace/Collaborators.vue";
import LoadingAnimation from "@/components/ui/LoadingAnimation.vue";
import { reportError } from "@/tools/error-handler";

@Component({
  components: {
    ProfileOpener,
    LeftPanel,
    Button,
    Name,
    Collaborators,
    LoadingAnimation
  }
})
export default class Workspace extends Vue {
  deleting = false;
  loading = true;
  saving = false;
  saved = false;
  workspaceLoaded = false;

  @Watch("$store.state.user.data", { immediate: true, deep: true })
  async onUserUpdate(user: UserModel) {
    if (user.uid && !this.workspaceLoaded) {
      this.getWorkspace();
    }
  }

  get saveWorkspaceAllowed(): boolean {
    if (this.$store.state.workspace.name) {
      return true;
    } else {
      return false;
    }
  }

  async saveWorkspace(): Promise<void> {
    this.saving = true;
    const updateSuccessful = await this.$store.dispatch("updateWorkspace");
    if (!updateSuccessful) {
      reportError("11010", "Workspace could not be updated.");
    } else {
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    }
    this.saving = false;
  }

  async deleteWorkspace(): Promise<void> {
    const workspace: any = this.$store.state.workspaces.find(
      (workspace: any) => workspace.id === this.$store.state.workspace.id
    );
    if (workspace.projects && workspace.projects.length > 0) {
      alert("You can not delete a workspace as long as it contains projects.");
      return;
    }
    if (workspace.collaborators.length > 1) {
      alert("You can not delete a workspace as long as it has collaborators.");
      return;
    }
    const result = confirm(
      "Are you sure you want to delete this workspace? All settings will be lost."
    );
    if (result) {
      this.deleting = true;
      const deleteSuccessful = await this.$store.dispatch("deleteWorkspace");
      this.deleting = false;
      if (deleteSuccessful) {
        this.$router.push({ name: "dashboard" });
      }
    }
  }

  async getWorkspace(): Promise<void> {
    this.workspaceLoaded = true;
    this.loading = true;
    await this.$store.dispatch(
      "fetchWorkspace",
      this.$route.params.workspaceId
    );
    this.loading = false;
  }
}
