var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"project"},[_c('div',{staticClass:"flex-row space"},[_c('div',{staticClass:"flex-row"},[_vm._m(0),(_vm.project.domain.nameStaging || _vm.project.domain.name)?_c('div',{staticClass:"highlight-icon-block right-10",staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleOpenConfigurator}},[_c('div',{staticClass:"_15px-icon"},[_c('img',{attrs:{"src":_vm.project.domain
                ? 'https://s2.googleusercontent.com/s2/favicons?domain_url=https://' +
                  (_vm.project.domain.nameStaging || _vm.project.domain.name)
                : 'https://i1.wp.com/aungmyat.co.uk/wp-content/uploads/2015/07/default-placeholder.png?fit=1024%2C1024&ssl=1',"loading":"lazy","width":"14","alt":""}})])]):_vm._e(),(_vm.project.domain.nameStaging || _vm.project.domain.name)?_c('h5',{staticClass:"bold",staticStyle:{"cursor":"pointer"},on:{"click":_vm.handleOpenConfigurator}},[_vm._v(" "+_vm._s(_vm.project.domain.nameStaging ? _vm.project.domain.nameStaging.length > 18 ? _vm.project.domain.nameStaging.substring(0, 18) + "..." : _vm.project.domain.nameStaging : _vm.project.domain.name.length > 18 ? _vm.project.domain.name.substring(0, 18) + "..." : _vm.project.domain.name)+" ")]):_vm._e(),(!(_vm.project.domain.nameStaging || _vm.project.domain.name))?_c('h5',{staticClass:"bold _50"},[_vm._v(" Draft ")]):_vm._e()]),(_vm.isAdminOfWorkspace)?_c('Button',{attrs:{"iconName":"settings","isSquare":true,"styles":"super-light"},on:{"click":_vm.openProject}}):_vm._e()],1),_c('div',{staticClass:"info-sec top-15"},[_c('div',{staticClass:"flex-row"},[_c('p',{staticClass:"left-3"},[_vm._v("Unique daily site visits:")]),_c('p',{staticClass:"_100 left-3"},[_vm._v(" "+_vm._s(_vm.trafficValue !== null ? _vm.trafficValue : "")+" ")]),_c('p',{staticClass:"left-3"},[_vm._v(_vm._s(_vm.trafficDate ? " (" + _vm.trafficDate + ")" : ""))])]),_c('div',{staticClass:"traffic-chart curve",staticStyle:{"padding":"0px","overflow":"hidden"}},[_c('TrendChart',{attrs:{"datasets":_vm.usage,"grid":{
          verticalLines: false,
          horizontalLines: true
        },"padding":"10 2 2 2","interactive":true},on:{"mouse-move":_vm.displayCurrentValue}})],1)]),_c('div',{staticClass:"info-sec top-15"},[_c('div',{staticClass:"flex-row"},[_c('p',{staticClass:"_100 left-3"},[_vm._v(_vm._s(_vm.viewsForTimeframe))]),_c('p',{staticClass:"left-right-3"},[_vm._v("of")]),_c('p',{staticClass:"_100"},[_vm._v(" "+_vm._s(_vm.formatDecimalNumber(_vm.includedViewsInTimeframe))+" ")]),_c('p',{staticClass:"left-3"},[_vm._v(" site visits used ")])]),_c('p',[_vm._v(" "+_vm._s(_vm.project.plan !== "development" ? ("- In this billing period (during the last " + (_vm.daysSinceTimestamp( _vm.project.subscription.currentPeriodStart )) + " days)") : "- During the last 30 days")+" ")]),_c('div',{staticClass:"progress-bar",staticStyle:{"overflow":"hidden"}},[_c('div',{staticClass:"progress-bar-indicator",style:({
          width: _vm.percentageOfViewsUsed + '%',
          backgroundColor:
            _vm.percentageOfViewsUsed > 70
              ? _vm.percentageOfViewsUsed > 90
                ? '#FF715D'
                : '#FFBE5D'
              : ''
        })})])]),(_vm.isAdminOfWorkspace || _vm.project.initialSetupCompleted)?_c('Button',{staticStyle:{"margin-top":"15px"},attrs:{"styles":"light","iconName":"arrow-right","text":_vm.project.initialSetupCompleted ? 'Open configurator' : 'Complete setup',"iconRight":true},on:{"click":_vm.handleOpenConfigurator}}):_vm._e(),(!_vm.isAdminOfWorkspace && !_vm.project.initialSetupCompleted)?_c('div',{staticStyle:{"height":"48px"}}):_vm._e(),_c('div',{staticClass:"info-sec top-15"},[_c('p',[_vm._v("Plan")]),_c('div',{staticClass:"plan-div top-5",class:{
        live: _vm.project.plan === 'launch',
        business: _vm.project.plan === 'business'
      }},[_c('img',{staticClass:"_10px-icon\n      right-5",attrs:{"src":_vm.project.plan === 'launch'
            ? '/images/plan-black.svg'
            : '/images/plan-white.svg',"loading":"lazy","alt":""}}),_c('div',{staticClass:"text-block plan-font"},[_vm._v(" "+_vm._s(_vm.project.plan === "launch" ? "LAUNCH" : _vm.project.plan === "business" ? "BUSINESS" : "DEVELOPMENT")+" ")])])])],1)}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"drag-n-drop-handle"},[_c('img',{staticClass:"_15px-icon",attrs:{"src":"/images/drag.svg","loading":"lazy","alt":""}})])}]

export { render, staticRenderFns }