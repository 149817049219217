





















import { Component, Vue } from "vue-property-decorator";
import LoadingAnimation from "./LoadingAnimation.vue";

const Props = Vue.extend({
  props: {
    text: {
      type: String,
      default: ""
    },
    styles: {
      type: String,
      default: "",
      validator: (value: string) => {
        if (
          value === "" ||
          value === "light" ||
          value === "light outline" ||
          value === "light blue" ||
          value === "light red" ||
          value === "super-light"
        ) {
          return true;
        } else {
          return false;
        }
      }
    },
    size: {
      type: String,
      default: "medium",
      validator: (value: string) => {
        if (value === "big" || value === "medium") {
          return true;
        } else {
          return false;
        }
      }
    },
    isActive: {
      type: Boolean,
      default: true
    },
    isSquare: {
      type: Boolean,
      default: false
    },
    iconName: {
      type: String,
      default: ""
    },
    loading: {
      type: Boolean,
      default: false
    },
    iconRight: {
      type: Boolean,
      default: false
    }
  }
});

@Component({
  components: {
    LoadingAnimation
  }
})
export default class Button extends Props {
  get classes(): string {
    return (
      this.styles +
      " " +
      (this.size === "big" ? "big" : "") +
      " " +
      (this.isSquare ? "square" : "") +
      " " +
      (this.isActive && !this.loading ? "" : "inactive")
    );
  }

  click(): void {
    if (this.isActive && !this.loading) this.$emit("click");
  }
}
