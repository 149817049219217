





































































import { Component, Vue } from "vue-property-decorator";
import Button from "../ui/Button.vue";

@Component({
  components: { Button }
})
export default class LeftPanel extends Vue {
  navigateTo(routeName: string): void {
    if (this.$router.currentRoute.name !== routeName) {
      this.$router.push({ name: routeName });
    }
  }

  openURL(url: string): void {
    window.open(url);
  }

  openURLInNewWindow(url: string): void {
    window.open(url, "_blank");
  }
}
