













































































import { Component, Vue, Watch } from "vue-property-decorator";
import Project from "./Project.vue";
import { WorkspaceModel } from "@/models/Workspace.ts";
import { ProjectModel } from "@/models/Project";
import draggable from "vuedraggable";
import Button from "@/components/ui/Button.vue";
import { reportError } from "@/tools/error-handler";

const Props = Vue.extend({
  props: {
    workspace: {
      type: Object
    }
  }
});

@Component({
  components: { Project, draggable, Button }
})
export default class Workspace extends Props {
  projects = [];

  movedProject(move: any): void {
    let project: ProjectModel;
    if (move.moved) {
      this.saveUpdatedProjectOrder();
    } else if (move.added) {
      this.saveUpdatedProjectWorkspaceId(move.added.element);
      this.saveUpdatedProjectOrder();
    } else if (move.removed) {
      this.saveUpdatedProjectOrder();
    }
  }

  saveUpdatedProjectOrder(): void {
    this.projects.forEach((project: any, index) => {
      project.sorting = index;
      this.$store.dispatch("updateProjectSorting", project);
    });
  }

  saveUpdatedProjectWorkspaceId(project: ProjectModel): void {
    project.workspaceId = this.workspace.id;
    this.$store.dispatch("updateProjectWorkspaceId", project);
  }

  async createProject(): Promise<void> {
    reportError(
      "15000",
      "New project creation is disabled in Wized V1. Please visit app.wized.com to create a project in Wized V2."
    );
  }

  openWorkspace(): void {
    this.$router.push({
      name: "workspace",
      params: { workspaceId: this.workspace.id }
    });
  }

  checkMove(e: any): boolean {
    if (e.dragged.className.includes("not-draggable")) {
      return false;
    } else {
      return true;
    }
  }

  @Watch("workspace.projects", { immediate: true, deep: true })
  async onWorkspaceUpdate(workspace: WorkspaceModel) {
    this.projects = this.workspace.projects.sort(
      (a: any, b: any) => a.sorting - b.sorting
    );
  }

  get isAdminOfWorkspace(): boolean {
    return this.workspace.owner === this.$store.state.user.data.uid;
  }

  get shownProjects(): Array<ProjectModel> {
    const projects = this.projects;
    const filteredProjects = this.projects.filter((project: any) =>
      project.domain?.name
        ?.toLowerCase()
        .includes(this.$attrs.search.toLowerCase())
    );
    return filteredProjects;
  }

  get numberOfCollaborators(): string {
    let result = "";
    if (
      this.workspace.collaborators.length > 1 &&
      this.workspace.collaborators.length <= 2
    ) {
      result =
        (this.workspace.collaborators.length - 1).toString() + " Collaborator";
    } else if (this.workspace.collaborators.length > 2) {
      result =
        (this.workspace.collaborators.length - 1).toString() + " Collaborators";
    } else if (this.workspace.collaborators.length === 1) {
      result = "Only you";
    }
    return result;
  }
}
