import { db, firebase, analytics } from "./firebase";
import { reportError } from "../tools/error-handler";
import envConfig from "@/envConfig";
import { UserModel } from "../models/User";

const findById = (uid: string): Promise<UserModel> => {
  return new Promise<UserModel>(resolve => {
    db.collection("users")
      .doc(uid)
      .get()
      .then(doc => {
        if (doc.exists) {
          resolve(doc.data() || {});
        } else {
          resolve({});
        }
      })
      .catch(error => {
        reportError("10001", "Error while loading user", error);
        resolve({});
      });
  });
};

const create = (user: UserModel): Promise<UserModel> => {
  return new Promise<UserModel>(resolve => {
    user.createdTimestamp = Math.round(Date.now() / 1000);
    user.updatedTimestamp = Math.round(Date.now() / 1000);
    db.collection("users")
      .doc(user.uid)
      .set(user, { merge: true })
      .then(() => {
        resolve(user);
      })
      .catch(error => {
        reportError("10002", "Failed to create new user", error);
        resolve({});
      });
  });
};

const update = (user: UserModel): Promise<boolean> => {
  return new Promise<boolean>(resolve => {
    user.updatedTimestamp = Math.round(Date.now() / 1000);
    db.collection("users")
      .doc(user.uid)
      .set(user, { merge: true })
      .then(() => {
        resolve(true);
      })
      .catch(function(error) {
        reportError("10002", "Error while updating user", error);
        resolve(false);
      });
  });
};

const getIdToken = (): Promise<string> => {
  return new Promise<string>((resolve, reject) => {
    firebase
      .auth()
      .currentUser?.getIdToken(true)
      .then(function(idToken) {
        resolve(idToken);
      })
      .catch(function(error) {
        reportError("10004", "Error while retrieving user id token", error);
        resolve("");
      });
  });
};

const createStripeCustomer = async (user: UserModel): Promise<UserModel> => {
  const userToken = await getIdToken();
  return new Promise<UserModel>(resolve => {
    fetch(envConfig.serverUrl + "v1/stripe/create-customer", {
      method: "post",
      headers: {
        "Content-Type": "application/json",
        Authorization: "Bearer " + userToken
      }
    })
      .then(response => {
        return response.json();
      })
      .then(async body => {
        if (body.stripeCustomerId) {
          user.stripe = {
            customerId: body.stripeCustomerId
          };
          resolve(user);
        } else {
          reportError(
            "10003",
            "Create Stripe customer request did not return stripeCustomerId"
          );
          resolve(user);
        }
      })
      .catch(err => {
        reportError("10004", "Stripe customer could not be created.", err);
        resolve(user);
      });
  });
};

const User = {
  findById,
  create,
  update,
  createStripeCustomer,
  getIdToken
};

export default User;
