

















































































import { UserModel } from "@/models/User";
import { Component, Vue, Watch } from "vue-property-decorator";
import InputText from "@/components/ui/InputText.vue";
import InputDropdown from "@/components/ui/InputDropdown.vue";
import ProfileOpener from "@/components/auth/ProfileOpener.vue";
import LeftPanel from "@/components/nav/LeftPanel.vue";
import Button from "@/components/ui/Button.vue";
import countries from "@/misc/countryList.ts";
import { reportError } from "@/tools/error-handler.ts";

@Component({
  components: {
    ProfileOpener,
    LeftPanel,
    Button,
    InputText,
    InputDropdown
  }
})
export default class Account extends Vue {
  billingInformation = "";
  loading = false;
  saved = false;

  @Watch("$store.state.user.data", { immediate: true, deep: true })
  onUserUpdate(user: UserModel) {
    if (user.uid) {
      this.billingInformation =
        this.$store.state.user.data.billing &&
        this.$store.state.user.data.billing.billingInformation
          ? this.$store.state.user.data.billing.billingInformation
          : "";
    }
  }

  @Watch("billingInformation")
  updateBillingInformation() {
    this.updateValue("billingInformation", this.billingInformation);
  }

  updateValue(valueName: string, value: string): void {
    const user = this.$store.state.user.data;
    if (valueName === "firstName") {
      user.firstName = value;
    } else if (valueName === "lastName") {
      user.lastName = value;
    } else if (valueName === "country") {
      if (!user.billing) user.billing = {};
      user.billing.country = value;
    } else if (valueName === "billingInformation") {
      if (!user.billing) user.billing = {};
      user.billing.billingInformation = value;
    }
    this.$store.commit("SET_USER", user);
  }

  async updateUser(): Promise<void> {
    this.loading = true;
    const updateUserSuccess = await this.$store.dispatch("updateUser");
    if (!updateUserSuccess) {
      reportError("10010", "User could not be updated.");
    } else {
      this.saved = true;
      setTimeout(() => {
        this.saved = false;
      }, 3000);
    }
    this.loading = false;
  }

  get countriesFormatted(): Array<any> {
    const result: any = [];
    countries.forEach(country => {
      result.push({ id: country.code, value: country.name });
    });
    return result;
  }
}
