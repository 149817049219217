const reportError = (
  errorCode: string,
  errorDescription: string,
  providedError?: any
) => {
  const error = new Error(
    errorCode + " | " + errorDescription + " | " + providedError
  );
  console.error(error);

  alert(errorDescription);
};

export { reportError };
